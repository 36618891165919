import Vue from 'vue'
import VueRouter from 'vue-router'
let Home = ()=> import('../views/Home.vue');

Vue.use(VueRouter)

const routes = [
    {
      path: "/",
      redirect: "/login",
    },
    // {
    //   path: "/home",
    //   name: "home",
    //   title: '首页',
    //   component: ()=> import('../views/Home.vue'),
    // },
    {
        path: "/protocol",
        name: "protocol",
        title: '协议',
        component: ()=> import('../views/protocol/protocolShow.vue'),
    },
    {
      path: "/login",
      name: "login",
      title: '首页',
      component: ()=> import('../views/Login.vue'),
    },{
        path: "/visition",
        name: "visition",
        title: '首页',
        component: ()=> import('../views/visition.vue'),
    },{
      path: "/register",
      name: "register",
      title: '注册',
      redirect: '',
      component: ()=> import('../views/register.vue'),
  },
  {
      path: "/findPwdBack",
      name: "findPwdBack",
      title: '找回密码',
      redirect: '',
      component: ()=> import('../views/findPwdBack.vue'),
  },
  {
    path: "/index",
    name: "index",
    title: '首页',
    redirect: '',
    component: Home,
    children: [
        {
            path: "/appHome",
            name: "appHome",
            title: '应用商城',
            redirect: '',
            component: ()=> import('../views/appHome/Index.vue'),
        },
        {
            path: "/myApps",
            name: "myApps",
            title: '工作台',
            redirect: '',
            component: ()=> import('../views/myApps/Index.vue'),
        },
        {
            path: "/entService",
            name: "entService",
            title: '企业服务',
            redirect: '',
            component: ()=> import('../views/entService/Index.vue'),
        },
        {
            path: "/identityResolution",
            name: "identityResolution",
            title: '标识解析',
            redirect: '',
            component: ()=> import('../views/identityResolution/Index.vue'),
        },
        {
            path: "/developerPlat",
            name: "developerPlat",
            title: '开发者平台',
            redirect: '',
            component: ()=> import('../views/developerPlat/Index.vue'),
        },
        {
            path: "/subscribeMgt",
            name: "subscribeMgt",
            title: '订阅管理',
            redirect: '',
            component: ()=> import('../views/subscribeMgt/Index.vue'),
        },
        {
            path: "/visitedRecord",
            name: "visitedRecord",
            title: '访问记录',
            redirect: '',
            component: ()=> import('../views/visitedRecord/Index.vue'),
        },
        {
            path: "/sysMgt",
            name: "sysMgt",
            title: '系统管理',
            redirect: '',
            component: ()=> import('../views/sysMgt/Index.vue'),
        },
        {
            path: "/sysMgt/selfService",
            name: "selfService",
            title: '自有服务配置',
            redirect: '',
            component: ()=> import('../views/sysMgt/SelfService.vue'),
        },
        {
            path: "/sysMgt/msgSetting",
            name: "msgSetting ",
            title: '信息设置',
            redirect: '',
            component: ()=> import('../views/sysMgt/MsgSetting.vue'),
        },
        {
            path: "/sysMgt/DepartmentMgt",
            name: "DepartmentMgt",
            title: '部门管理',
            redirect: '',
            component: ()=> import('../views/sysMgt/DepartmentMgt.vue'),
        },
        {
            path: "/sysMgt/memberMgt",
            name: "memberMgt",
            title: '成员管理',
            redirect: '',
            component: ()=> import('../views/sysMgt/MemberMgt.vue'),
        },
        {
            path: "/sysMgt/roleMgt",
            name: "roleMgt",
            title: '角色管理',
            redirect: '',
            component: ()=> import('../views/sysMgt/RoleMgt.vue'),
        },
        {
            path: "/sysMgt/userCenter",
            name: "userCenter",
            title: '角色管理',
            redirect: '',
            component: ()=> import('../views/sysMgt/UserCenter.vue'),
        }
    ]
  },
  {
    path: "/appDetail",
    name: "appDetail",
    title: '应用详情',
    redirect: '',
    component: ()=> import('../views/appHome/AppDetail.vue'),
  },
  {
      path:'/*',
      component:()=>import('../views/404.vue')
  }
]

const router = new VueRouter({
	mode: 'hash',
	routes
})

export default router
